<template>
  <div class="admin-page-members">
    <BasicTable
      ref="table"
      :table-name="tableName"
      :setting-columns="columns"
      :actions="actions"
      :filters.sync="filters"
      :search="search"
      :setting-apis="apis"
    >
      <template v-slot:header_right_table>
        <button v-if="$refs.table && $refs.table.entries.length" @click="exportListCsv"
                class="btn btn-complete app-text mr-1"> {{
            $t('members.export_csv')
          }}
        </button>
      </template>
    </BasicTable>
  </div>
</template>
<script>
import BasicTable from '@components/_common/List/BasicTable';

export default {
  data() {
    return {
      create: false,
      active: false,
      tableName: this.$t('users.admin user list'),
      apis: {
        list: {
          endpoint: this.$consts.API.ADMIN.MEMBERS_LIST,
        },
        delete: {
          endpoint: this.$consts.API.ADMIN.MEMBERS_DELETE,
          params: {active: 0}
        }
      },
      actions: {
        editEntry: entry => this.$router.push({name: this.$consts.ROUTES.ADMIN.MEMBERS_UPDATE, params: {id: entry.id}}),
        //deleteEntry: true,
        exportEntries: this.exportListCsv,
        is_user: true,
        others: [
          {
            showCondition: entry => {
              if (!entry.user) {
                return false
              }
              return !entry.user.email_verified_at
            },
            label: '認証URLの再送信',
            click: entry => this.resentActiveEmail(entry),
            contentHtml: entry => `<div class="btn-tool default text-center">認証URLの再送信</div>`,
          },
        ]
      },
      search: {
        searchAll: false,
        reset: true,
      },
      filters: {
        active_equal: {
          name: 'user_type',
          type: 'equal',
          label: this.$t('members.user_type'),
          uiType: 'select',
          ui_options: {
            // tags: true,
            closeOnSelect: true,
          },
          options: [
            {
              id: 0,
              name: this.$t('members.members_0')
            },
            {
              id: 1,
              name: this.$t('members.members_1')
            },
            {
              id: "none",
              name: this.$t('common.select_all'),
            },
          ],
          boundaryClass: "col-lg-6 col-md-6 col-sm-6"
        },
        name_multi_like: {
          name: 'name',
          type: 'multi_like',
          label: this.$t('members.name'),
          uiType: 'multi_select',
          ui_options: {
              // tags: true,
              closeOnSelect: true,
          },
          options: [],
          boundaryClass: "col-lg-6 col-md-6 col-sm-6",
        },
      },
      status: [
        {
          id: 0,
          name: this.$t('users.active_off')
        },
        {
          id: 1,
          name: this.$t('users.active_on')
        }
      ],
      entrySelectedDelete: {},
      entryToSave: {active: 1},
      entryToEdit: {},
      columns: [
        {name: 'id', label: "No."},
        {
          label: this.$t('members.name'),
          class: 'min-width-180',
          computedHtml: entry => {
            if (entry.sei != null || entry.mei != null) {
              return `<p class="app-table-p app-cell-tooltip" data-original-title='${this.htmlencode(entry.sei) + '　' +this.htmlencode(entry.mei)}'>${this.htmlencode(entry.sei) + '　' + this.htmlencode(entry.mei)}</p>`;
            }
          },
        },
        {
          label: this.$t('members.Furigana'),
          class: 'min-w-80',
          computedHtml: entry => {
            if (entry.sei_kana != null || entry.mei_kana != null) {
              return `<p class="app-table-p app-cell-tooltip" data-original-title="${entry.sei_kana + '　' +entry.mei_kana}">${entry.sei_kana + '　' +entry.mei_kana}</p>`;
            }
          },
        },
        {name: 'phone', label: this.$t('users.phone')},
        {name: 'email', label: this.$t('users.email')},
        {
          label: this.$t('members.gender'),
          class: 'min-w-80',
          computedHtml: entry => {
            if (entry.gender == 1) {
              return '<p class="app-table-p app-cell-tooltip" data-original-title="'+ this.$t('members.gender_1') +'">' + this.$t('members.gender_1') + '</p>';
            } else {
              return '<p class="app-table-p app-cell-tooltip" data-original-title="'+ this.$t('members.gender_0') +'">' +this.$t('members.gender_0') + '</p>';;
            }
          },
        },
        {name: 'birthday', label: this.$t('members.birthday')},
        {name: 'postal_code', label: this.$t('members.postal_code')},
        {
          label: this.$t('members.address'),
          class: 'min-width-180',
          computedHtml: entry => {
            let address = '';

            if (entry.prefecture !=  null) {
              address += entry.prefecture.name;
            }

            if (entry.city !=  null) {
              address += entry.city.name;
            }

            if (entry.street !=  null) {
              address += this.htmltrim(entry.street);
            }

            if (entry.building !=  null) {
              address += '　' + this.htmltrim(entry.building);
            }

            address = $.trim(address);

            return "<p class='app-table-p app-cell-tooltip' data-original-title='"+address+"'>" + address + "</p>";
          },
        },
        {
          label: this.$t('members.user_type'),
          class: 'min-w-80 text-left',
          computedHtml: entry => {
            if (entry.user_type == 1) {
              return '<p class="app-table-p app-cell-tooltip">' + this.$t('members.members_1') + '</p>'
            } else if(entry.user_type == 0) {
              return '<p class="app-table-p app-cell-tooltip">' + this.$t('members.members_0') + '</p>'
            } else {
              return ''
            }
          },
        },
        {name: 'created_at', label: this.$t('members.created_at'), class: 'min-width-180'},
        {
          name: 'last_login',
          label: this.$t('members.last_login'),
          class: 'min-width-180',
          computedHtml: entry => {
            return entry.user != null ? entry.user.last_login : ""
          },
        },
        {
          name: 'active',
          label: this.$t('users.active'),
          class: "text-center",
          computedHtml: entry => {
            if (entry.active) {
              return '<span class="alert alert-info">' + this.$t('users.active_on') + '</span>'
            } else {
              return '<span class="alert alert-disable">' + this.$t('users.active_off') + '</span>'
            }
          },
        },

      ]
    }
  },
  components: {BasicTable},
  mounted() {
    this.getNameAccount();
  },
  methods: {
    resentActiveEmail(entry) {
      if(entry.user) {
        this.$request.post(this.$consts.API.ADMIN.RESENT_ACTIVE_EMAIL(entry.user.id)).then(res => {
          if (!res.hasErrors()) {
            this.$appNotice.success(res.data.msg);
          } else {
            this.$appNotice.error(res.data?.error_msg)
          }
        })
      } else {
        this.$appNotice.error('エラーが発生しています。 もう一度やり直してください。')
      }
    },
    htmlencode(str)
    {
      var p = document.createElement("p");
      p.textContent = str;
      var converted = p.innerHTML;

      return converted;
    },
    htmltrim(str)
    {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = str;
      return tmp.textContent || tmp.innerText || "";
    },
    getNameAccount()
    {
      this.$request.get(this.$consts.API.ADMIN.MEMBERS_SELECT_NAME).then(res => {
        if(!res.hasErrors()) {
          let data = [];
          let new_data = [];

          _.forEach(res.data, (value) => {
            if (!data.includes(value.name)) {
              data.push(value.name);
            }
          });

          _.forEach(data, (value1) => {
            new_data.push({
              id: value1,
              name: value1
            })
          });

          this.filters.name_multi_like.options = new_data;
        }
      });
    },
    exportListCsv()
    {
      let params = {};
      const filtersClone = (this.filters);

      _.forEach(this.$route.query, (value, columnString) => {
        _.forEach(filtersClone, (filter) => {
          if (columnString.indexOf(filter.name) > 0 &&
            columnString.indexOf(filter.type) > 0 &&
            value != "none"
          ) {
            params[`filters[${filter.name}][${filter.type}]`] = value
          }
        })
      });

      this.$request.get(this.$consts.API.ADMIN.MEMBERS_EXPORT, {...params}).then(res => {
        if (!res.hasErrors()) {
          this.downloadFromUrl(res.data.url)
        } else {
          this.$appNotice.error();
        }
      })
    }
  }
}
</script>

<style>
  .admin-page .modal_form .close {
    display: none;
  }
  .admin-page-members .search-content .btn-default{
    margin-left: 7px;
  }
  .min-width-180 {
    min-width: 180px;
  }
</style>
